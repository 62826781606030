import React from 'react';
import { Helmet } from 'react-helmet';

import { css } from '@emotion/react';

import { Footer } from '../components/Footer';
import { PostFullContent } from '../components/PostContent';
import { SiteHeader } from '../components/header/SiteHeader';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import {
  inner,
  outer,
  SiteMain,
} from '../styles/shared';
import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';
import { colors } from '../styles/colors';

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }
`;

const About: React.FC = () => (
  <IndexLayout>
    <Helmet>
      <title>Privacy Policy</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <SiteHeader isHome={false}/>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <div css={inner}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader className="post-full-header">
              <PostFullTitle className="post-full-title">プライバシーポリシー</PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <p>以下に当サイトのプライバシーポリシーを記載します。</p>
                <section id="contents">
                  <h2 key="1-基本方針">1.基本方針</h2>
                  <p>
                    当サイトでは、個人情報保護の重要性を強く認識し、個人情報を取り扱う際には個人情報保護に関する法令・国が定める指針・その他の規範を遵守し、個人情報の取得、利用、管理を利用目的の範囲内で適切に行います。
                  </p>
                  <h2 key="2-情報の取得">2.情報の取得</h2>
                  <h3 key="2-Cookie">Cookie</h3>
                  <p>
                    当サイトは、コンテンツの充実やサイト改善のため、後述するアクセス解析サービスを利用しており、これらのサービスを利用する上で、Cookie を利用して情報を収集することがあります。 ただし、Cookie により取得する情報には、ユーザ名やメールアドレスなど個人を特定するものは一切含まれません。
                  </p>
                  <p>
                    ブラウザでクッキーの受け入れを拒否するように設定することで、Cookie による情報収集を拒否することも可能です。 その際は当サイトが正しく機能しない場合もありますので、あらかじめご了承ください。
                  </p>
                  <h2 key="3-情報の取得">3.当サイトが利用・提携しているサービス</h2>
                  <h3 key="3-GoogleAnalytics">Google Analytics</h3>
                  <p>
                    当サイトでは、アクセス解析ツール「Google Analytics」を利用しています。
                  </p>
                  <p>
                    Google Analytics はトラフィックデータの収集のために Cookie を使用しています。 トラフィックデータは匿名で収集されており、個人を特定するものではありません。
                  </p>
                  <p>
                    Google Analytics の利用規約は「<a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="nofollow noopener noreferrer">Google アナリティクス利用規約</a>」をご覧ください。
                  </p>
                  <h2 key="5-プライバシーポリシーの変更について">5. プライバシーポリシーの変更について</h2>
                  <p>
                    当サイトは、本ポリシーの内容を適宜見直し改善に努めます。
                  </p>
                  <p>
                    修正された最新のプライバシーポリシーは常に本ページにて開示されます
                  </p>
                </section>
              </div>
            </PostFullContent>
          </article>
        </div>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default About;
